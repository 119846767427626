document.addEventListener("DOMContentLoaded", function() {
    var lazyloadImages;    
  
    if ("IntersectionObserver" in window) {
      lazyloadImages = document.querySelectorAll("img[data-src]");
      var imageObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            var image = entry.target;
            image.src = image.dataset.src;
            image.removeAttribute('data-src');
            imageObserver.unobserve(image);
          }
        });
      });
  
      lazyloadImages.forEach(function(image) {
        imageObserver.observe(image);
      });

      // Lazyload for css with link[data-href]
      var lazyloadLinks = document.querySelectorAll("link[data-href]");
      var linkObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            var link = entry.target;
            link.href = link.dataset.href;
            link.removeAttribute('data-href');
            linkObserver.unobserve(link);
          }
        });
      });

      lazyloadLinks.forEach(function(link) {
        linkObserver.observe(link);
      });

      // Lazyload for js with script[data-src]
      var lazyloadScripts = document.querySelectorAll("script[data-src]");
      var scriptObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            var script = entry.target;
            script.src = script.dataset.src;
            script.removeAttribute('data-src');
            scriptObserver.unobserve(script);
          }
        });
      });

      lazyloadScripts.forEach(function(script) {
        scriptObserver.observe(script);
      });

    } else {  
      var lazyloadThrottleTimeout;
      lazyloadImages = document.querySelectorAll("img[data-src]");
      lazyloadScripts = document.querySelectorAll("script[data-src]");
      lazyloadLinks = document.querySelectorAll("link[data-href]");
      
      function lazyload () {
        if(lazyloadThrottleTimeout) {
          clearTimeout(lazyloadThrottleTimeout);
        }    
  
        lazyloadThrottleTimeout = setTimeout(function() {
          var scrollTop = window.pageYOffset;
          lazyloadImages.forEach(function(img) {
              if(img.offsetTop < (window.innerHeight + scrollTop)) {
                img.src = img.dataset.src;
                img.removeAttribute('data-src');
              }
          });
          if(lazyloadImages.length == 0) { 
            document.removeEventListener("scroll", lazyload);
            window.removeEventListener("resize", lazyload);
            window.removeEventListener("orientationChange", lazyload);
          }

          lazyloadScripts
        }, 20);
      }
  
      document.addEventListener("scroll", lazyload);
      window.addEventListener("resize", lazyload);
      window.addEventListener("orientationChange", lazyload);
    }
  });